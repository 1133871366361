import React from 'react'
import { images } from 'config/images'
import { EXTERNAL_LINK } from 'config/utils'

export const TechnologyPracServicesProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Develop a successful digital product with the{' '}
        <span className="heading-red">latest technology practices</span>
      </>
    ),
    bannerContent:
      'Simform relies on modern but tested technology practices. Let’s go beyond templates and hard-and-fast rules and focus on positive business outcomes instead. Have an efficient project roadmap, including the latest technologies, tools, processes, and approaches to solve the most complex challenges.',
    BannerAlt: 'Technology Practices',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        Why Simform for{' '}
        <span className="highlight-head">Technology Practices?</span>
      </>
    ),
    headDetails: [
      {
        Para: (
          <>
            The demand for tech-driven business transformation is rapidly
            growing. You cannot derive business transformation until you lead
            the change with tech practices - fused at the very core of your
            operations. Simform is a key enabler of this change. We assist you
            in identifying and solving the most critical business challenges
            with proven tech practices that align with your customer needs and
            organizational goals.
            <br /> <br />
            With Simform, you get to employ digital experience design &
            delivery, software development, security testing, quality assurance,
            and more. Rest assured, we’re on our way to getting CMMI level 3 and
            ISO 27001 certifications confirming our level of business maturity
            and organizational process standardization.
          </>
        ),
      },
      {
        detailsList: [
          'Standardized processes',
          'Core digital transformation',
          'Data-driven and agile',
          'Ideation, strategy, design.',
          'Advanced partnerships like CMMI Level 3',
          'Transparent and delivery-oriented culture',
        ],
      },
    ],
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogosrc: images.FujiFilmGray,
        clientLogosrcg: images.fujiFilmLogo,
        clientLogoAlt: 'Fuji Film',
      },
      {
        clientLogosrc: images.renesaslogoGray,
        clientLogosrcg: images.renesasLogo,
        clientLogoAlt: 'Renesas',
      },
      {
        clientLogosrc: images.Hyundai_Glovis_logoGray,
        clientLogosrcg: images.hyundaiGlovisLogo,
        clientLogoAlt: 'Hyundai Glovis',
      },
      {
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogosrc: images.mitsubishilogoGray,
        clientLogosrcg: images.mitsubishiLogo,
        clientLogoAlt: 'Mitsubishi Electric',
      },
      {
        clientLogosrc: images.TrygGray,
        clientLogosrcg: images.trygCLogo,
        clientLogoAlt: 'Tryg',
      },
      {
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogosrcg: images.fihCLogo,
        clientLogosrc: images.FIHGray,
        clientLogoAlt: 'Fih',
      },
      {
        clientLogosrc: images.HiltonGray,
        clientLogosrcg: images.hiltonLogo,
        clientLogoAlt: 'Hilton',
      },
      {
        clientLogosrc: images.kirloskarlogoGray,
        clientLogosrcg: images.kirloskarBrothersLogo,
        clientLogoAlt: 'kirloskar Brothers',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  serviceScrollSecProps: {
    servicesHeading: (
      <>
        How Technology practices impact{' '}
        <span className="highlight-head">digital transformation</span>
      </>
    ),
    sidebarHeading: 'Services',
    serviceListProps: [
      {
        heading: 'Project Strategy',
        className: 'custom-software',
        mainPara:
          'Unique business challenges, growing customer expectations, and time-to-market deadlines are best met by an agile & adaptive project strategy. Simform’s agile team transforms your cultural journey based on customer feedback and continuously ships new software on time & within budget.',
        listItem: [
          {
            title: 'Agile transformation',
            para:
              'Simform, with this iterative approach, lets you thrive in a collaborative, flexible, self-organizing, and fast-changing environment.',
          },
          {
            title: 'Agile and DevOps acceleration',
            para:
              'Innovation design thinking frameworks coupled with Agile and DevOps help your product vision convert into rapidly built digital solutions.',
          },
          {
            title: 'Product delivery',
            para:
              'Overcome challenges of software delivery and have an end-to-end view of software development for successful product delivery in a chaotic world.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/project-strategy/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Technology partnership',
        className: 'software-testing',
        mainPara:
          'Simform partners with top-tier technology drivers to increase the technical expertise and development capabilities that supercharge your unique business requirements with state-of-art technologies.',
        listItem: [
          {
            title: 'AWS Premier Consulting Partner',
            para:
              'Our AWS-certified developers, architecture associates, and cloud consultants assist you with various cloud-based services, including cloud deployment, networking, data engineering, CloudOps, and more.',
          },
          {
            title: 'Vonage API Integration Partner',
            para:
              'This partnership enables us to facilitate the integration of text, SMS, voice, video, and social to apps, databases, and systems so that you can deliver unique customer experiences.',
          },
          // {
          //   title: 'Microsoft Gold Partner',
          //   para:
          //     'Sharing the vision of accelerating enterprise innovations with Microsoft, Simform offers high-quality products and ensures your business transformation is in safe hands.',
          // },
          {
            title: 'ButterCMS Certified Partner',
            para:
              'With ButterCMS’s flexibility & scalability and Simform’s product engineering skills, you’ll have the technical prowess needed to build content-driven products that are easy to scale.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/technology-partners/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Process Management',
        className: 'enterprise-mobile',
        mainPara: (
          <>
            When looking for someone to consult and develop your product, you
            need a partner to ensure your pain points go beyond consulting and
            fuse into the development process. On its way to getting CMMI level
            3 and ISO 27001 certifications, Simform is the perfect partner
            you’ve been looking for to analyze, define, optimize, monitor, and
            control your business processes with the right tools and resources.
          </>
        ),
        listItem: [
          {
            title: 'Project assessment',
            para:
              'Have matured and refined project assessment offering end-to-end analysis of your project’s scope, objectives, scale, deliverables, budget, and impacts.',
          },
          {
            title: 'Project planning',
            para:
              'Work with our team to break down projects into work phases, tasks, and deliverables. Get high-level architecture solutions and functional scope. Choose from multiple project management methodologies (Scrum, Kanban, etc.). Get estimate timelines and costs. ',
          },
          {
            title: 'Project resource management',
            para:
              'Unleash the maximum potential of each resource, boost software delivery, and beat market volatility with proper resource management of people, tools, cost, and time.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/process-management/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Software testing',
        className: 'web-application',
        mainPara:
          'The biggest benefit of working with Simform is that you’ll get a complete package of software testing services. We take care of creating a test plan, assembling the right methodologies, and detecting defects that significantly improve software quality and decrease the time-to-market by at least 25%.',
        listItem: [
          {
            title: 'Test automation services',
            para:
              'We offer full-fledged test automation services, from planning and defining testing scope to selecting a tool and maintaining a test automation environment. Our automation architects define the complexity levels of test automation architecture before integrating it into CI/CD pipeline and aligning the test automation strategy to the development phases.',
          },
          {
            title: 'Microservices testing',
            para:
              'After deciding on software testing KPIs for microservices, we assign QA managers and test automation architects to prioritize the test scenarios and prepare a test strategy plan.',
          },
          {
            title: 'API testing',
            para:
              'Our QA managers have domain expertise in various API protocols like XML, SOAP, REST, etc. You’ll have industry-standard API testing and deliver an app that meets the needs of your target audience.',
          },
          {
            title: 'Performance testing',
            para: (
              <>
                Performance testing remains at the forefront of Simform's
                software QA testing services as it determines the overall
                application stability. It covers many applications, including
                complex transaction systems, client-server apps, and cloud
                databases.
              </>
            ),
          },
          {
            title: 'Security testing',
            para:
              'Have an application that meets security and compliance standards with our app security testing strategy that also ensures your data’s confidentiality & integrity are not compromised.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/software-testing/',
      },
      {
        heading: 'Technology stack ',
        className: 'api-integration',
        mainPara:
          'We understand that choosing the right tech is instrumental to your project’s success. Therefore, to ensure that we deliver the best quality software products, Simform has an extensive team of 1000+ in-house engineers with expertise in all the latest technologies & tools in the industry.',
        listItem: [
          {
            title: 'Backend',
            para:
              'We have an extensive team of developers specialized in backend technologies like Node.js, .NET Core, PHP, Java, Python, etc.',
          },
          {
            title: 'Frontend',
            para:
              'We create exceptional user experience, smooth user interface, and uncomplicated internal structures with technologies like, React Native, Angular.js, etc.',
          },
          {
            title: 'DevOps',
            para:
              'We look after the end-to-end delivery pipeline, automation, infrastructure management, deployment, orchestration, etc., with various DevOps tools and architectural approaches.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/technology-stack/',
        type: EXTERNAL_LINK,
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        Benefits of Working With
        <br /> <span className="highlight-head"> Simform</span>
      </>
    ),
    processPara:
      'There is no one-size, one-idea-fits-all kind of solution. At Simform, our software engineers are versatile and constantly improve on their skillset who can adapt and evolve according to the requirement of your application development.',
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Eminent expertise',
        workingBenefitsContent: (
          <>
            Our expert development team dedicates 100% of their time to the
            assigned client. They also nurture long-term relationships and gain
            in-depth knowledge of their business domain.
          </>
        ),
        benefitImgAlt: 'Eminent expertise',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Intrinsically agile',
        workingBenefitsContent: (
          <>
            Our strong pillar is that we follow adaptive planning in an agile
            approach to build apps flexibly. This helps us reduce repetition in
            work and empower cross-functional team collaboration to deliver
            projects on time.
          </>
        ),
        benefitImgAlt: 'Intrinsically agile',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'End-to-end services',
        workingBenefitsContent: (
          <>
            We have a proven record of satisfied clients. Be its cloud
            solutions, development or mobile, web or desktop apps, creation of
            custom, enterprise, or SaaS apps. We have a team ready for your
            every need.
          </>
        ),
        benefitImgAlt: 'End-to-end services',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Scalable team',
        workingBenefitsContent: (
          <>
            Build a distributed team filled with the right talent that can meet
            different market demands. Incorporate additional developers who can
            expand your existing team and add value to your project.
          </>
        ),
        benefitImgAlt: 'Scalable team',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Unmatched quality',
        workingBenefitsContent: (
          <>
            Beyond development, we take it seriously to make sure the deployed
            product is compliant with set standards for security, performance,
            dependencies, and so much more. We make room for constant
            improvement.
          </>
        ),
        benefitImgAlt: 'Unmatched quality',
      },
    ],
  },
  customDevProps: {
    serviceHeading: (
      <>
        Software Development in <br />
        <span className="highlight-head">Cutting-Edge Technologies</span>
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'Big Data',
        serviceParagraph: (
          <>
            Handle enormous amounts of data and limitless tasks virtually using
            cloud service-compatible frameworks like MongoDB, Hadoop, etc.
          </>
        ),
        serviceIcon: images.scloudApp,
        serviceAlt: 'Mobile',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: 'Internet of Things (IoT)',
        serviceParagraph: (
          <>
            Scale your application smartly. Ranging from middleware to
            architecture creation, our team builds sleek IoT interfaces for all
            MVP.
          </>
        ),
        serviceIcon: images.sInternetofThings,
        serviceAlt: 'Mobile',
        serviceClass: 'pale-grey',
      },
      {
        id: '3',
        serviceHeading: 'Artificial Intelligence (AI)',
        serviceParagraph: (
          <>
            Harness of the power of automation. We create AI capabilities that
            are reliable and interpretable with your software architecture.
          </>
        ),
        serviceIcon: images.sartificialIntelligence,
        serviceAlt: 'Manual',
        serviceClass: 'ice-blue',
      },
      {
        id: '4',
        serviceHeading: 'VR and AR app Development',
        serviceParagraph: (
          <>
            Leverage the power of VR and AR. Tap into our WebVR, photogrammetry,
            and Storyboarding skills in development.
          </>
        ),
        serviceIcon: images.svrArAppDev,
        serviceAlt: 'Software',
        serviceClass: 'pale',
      },
      {
        id: '5',
        serviceHeading: 'Machine Learning (ML)',
        serviceParagraph: (
          <>
            Develop your app intelligently. Build, train, and deploy ML models
            for various use cases of your application as desired.
          </>
        ),
        serviceIcon: images.smachineLearning,
        serviceAlt: 'Devops',
        serviceClass: 'pale-lavender',
      },
      {
        id: '6',
        serviceHeading: 'Blockchain',
        serviceParagraph: (
          <>
            Create DApps, build multi-blockchain compatible features, smart
            contracts with cryptocurrency solutions, and integrate REST APIs.
          </>
        ),
        serviceIcon: images.sblockchain,
        serviceAlt: 'Performance',
        serviceClass: 'very-light-pink-three',
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        <span className="highlight-head">Custom Software</span>
        <br />
        Development Case Studies
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  AboutprojectformProps: {
    title: (
      <>
        Tell Us About <span className="highlight-head">Your Project</span>
      </>
    ),
    formTitleL: 'Request a Free Quote',
    formInfo: 'Guaranteed response within one business day.',
    formDetailstitle: 'What will happen next?',
    formButtonText: 'LET"S GET STARTED',
    list: [
      'We’ll reach out to you within 24 hours.',
      'We’ll discuss your project and gather your requirements and business objectives, and develop a proposal accordingly.',
      'You can start 15-days risk-free trial with us.',
    ],
  },

  workFlowProps: {
    heading: (
      <>
        Software Development <br />
        <span className="highlight-head">Process</span>
      </>
    ),
    workFlowFirstRows: [
      {
        workFlowClass: 'very-light-blue',
        workFlowCount: '1',
        workFlowIcon: images.iconTeam,
        workFlowIconAlt: 'Choosing the team',
        workFlowTitle: 'Choosing the team',
        workFlowParagraph:
          'We pick a talent pool with the right team, the right expertise, domain knowledge, and willingness to adapt and evolve to your project environment.',
        workFlowArrowImg: images.iconFlowArrow1,
      },
      {
        workFlowClass: 'duck-egg-blue',
        workFlowCount: '2',
        workFlowIcon: images.iconSprint,
        workFlowIconAlt: 'Development methodology planning',
        workFlowTitle: 'Development methodology planning',
        workFlowParagraph:
          'Choose the suitable methodology that empowers the development process to be conducted at its best. Our team members follow sprint planning and agile techniques.',
        workFlowArrowImg: images.iconFlowArrow2,
      },
      {
        workFlowClass: 'light-peach',
        workFlowCount: '3',
        workFlowIcon: images.iconTech,
        workFlowIconAlt: 'Architecture creation',
        workFlowTitle: 'Architecture creation',
        workFlowParagraph:
          'We handle all architecture evolution requirements ranging from UI/UX enhancement, decoupling tightly packed features by migrating to microservices or modularizing the existing architecture.',
        workFlowArrowImg: images.iconFlowArrow3,
      },
    ],
    workFlowSecondRows: [
      {
        workFlowClass: 'light-green',
        workFlowCount: '4',
        workFlowIcon: images.iconStandUps,
        workFlowIconAlt: 'Project Delivery',
        workFlowTitle: 'Project Delivery',
        workFlowParagraph:
          'Beyond development, we set various endpoints where at each point, we deploy the completed project in several phases to reduce reiteration and ensure smooth deployment promptly as promised.',
        workFlowArrowImg: images.iconFlowArrow4,
      },
      {
        workFlowClass: 'very-light-blue',
        workFlowCount: '5',
        workFlowIcon: images.iconCodeReviews,
        workFlowIconAlt: 'Intelligent Testing',
        workFlowTitle: 'Intelligent Testing',
        workFlowParagraph:
          'We create useful test cases catered to identify septic storage problems, memory leaks, and security vulnerabilities. We used a variety of testing technologies and tools that best fit your project.',
        workFlowArrowImg: images.iconFlowArrow5,
      },
      {
        workFlowClass: 'light-peach-three',
        workFlowCount: '6',
        workFlowIcon: images.iconIterate,
        workFlowIconAlt: 'Periodical reviews',
        workFlowTitle: 'Periodical reviews',
        workFlowParagraph:
          'We conduct timely reviews to squash any foreseeable concern,  present weekly demos, and standups to address any existing bottlenecks to ensure everyone is on the same page.',
        workFlowArrowImg: images.iconFlowArrow6,
      },
    ],
  },
  industriesServeProps: {
    heading: (
      <>
        Industries <span className="highlight-head">We Serve</span>
      </>
    ),
    tabContents: [
      {
        tabName: 'Travel',
        contentHeading: 'Rent-a-Car Mobile App',
        contentPara:
          'Create a multilingual application that supports reviews, ratings, photos, 24/7 support with multi-payment gateway features to make the process of travel app services smooth and secured.',
        contentSingleProps: [
          {
            singleblkImg: images.mobilebus1,
            singleblkImg2x: images.mobilebus12x,
          },
          {
            singleblkImg: images.mobilebus2,
            singleblkImg2x: images.mobilebus22x,
          },
          {
            singleblkImg: images.mobilebus3,
            singleblkImg2x: images.mobilebus32x,
          },
        ],
      },
      {
        tabName: 'Finance',
        contentHeading: 'Mobile app for budgeting',
        contentPara:
          'Provide users with capabilities to calculate balances, manage personal investments, features to transfer money, and plan weekly/month budgets. We integrate advanced security APIs to ensure there is no breach.',
        contentSingleProps: [
          {
            singleblkImg: images.mobbanking1,
            singleblkImg2x: images.mobbanking12x,
          },
          {
            singleblkImg: images.mobbanking2,
            singleblkImg2x: images.mobbanking22x,
          },
          {
            singleblkImg: images.mobbanking3,
            singleblkImg2x: images.mobbanking32x,
          },
        ],
      },
      {
        tabName: 'Media & Entertainment',
        contentHeading: 'On-demand streaming Mobile/Web App',
        contentPara:
          'Attract users to your steaming platform with advanced analytics powered by AI, ML, and cloud power to create live-stream and online streaming from servers along with features of a recommendation engine, sharing, and tracking of user behavior.',
        contentSingleProps: [
          {
            singleblkImg: images.mediastream1,
            singleblkImg2x: images.mediastream12x,
          },
          {
            singleblkImg: images.mediastream2,
            singleblkImg2x: images.mediastream22x,
          },
          {
            singleblkImg: images.mediastream3,
            singleblkImg2x: images.mediastream32x,
          },
        ],
      },
      {
        tabName: 'Software',
        contentHeading: 'Warehouse Management Application',
        contentPara:
          'Build a custom SaaS application to manage inventory, stock-in, stock-out, etc., and serve as a central point for managing all B2B operations of the business. We can also create such Paas or APIaaS.',
        contentSingleProps: [
          {
            singleblkImg: images.b2b1,
            singleblkImg2x: images.b2b12x,
          },
          {
            singleblkImg: images.b2b2,
            singleblkImg2x: images.b2b22x,
          },
          {
            singleblkImg: images.b2b3,
            singleblkImg2x: images.b2b32x,
          },
        ],
      },
      {
        tabName: 'Healthcare',
        contentHeading: 'eReporting Mobile App',
        contentPara:
          'Leverage the power of the cloud to build a data management application where all patient reports are stored online for easier and quicker access. We create features for data storing, indexing, cache management, and dependency handling.',
        contentSingleProps: [
          {
            singleblkImg: images.doctor1,
            singleblkImg2x: images.doctor12x,
          },
          {
            singleblkImg: images.doctor2,
            singleblkImg2x: images.doctor22x,
          },
          {
            singleblkImg: images.doctor3,
            singleblkImg2x: images.doctor32x,
          },
        ],
      },
      {
        tabName: 'NGO',
        contentHeading: 'Public Welfare App',
        contentPara:
          'Development of a mobile application that stores necessary demographic detail of the user, which can be correlated against healthcare and food industry to serve the underprivileged in real-time. Predictive analysis through data science programs written for ML model is used in large here.',
        contentSingleProps: [
          {
            singleblkImg: images.publich1,
            singleblkImg2x: images.publich12x,
          },
          {
            singleblkImg: images.publich2,
            singleblkImg2x: images.publich22x,
          },
          {
            singleblkImg: images.publich3,
            singleblkImg2x: images.publich32x,
          },
        ],
      },
      {
        tabName: 'Education',
        contentHeading: 'Virtual Classroom App',
        contentPara:
          'Build a mobile and web app where CMS and CDN are leveraged to deliver content at its best to the students virtually. The app environment is also created with high data management tools to ensure easy access and designed in a way to maintain smooth navigation.',
        contentSingleProps: [
          {
            singleblkImg: images.educationElearning1,
            singleblkImg2x: images.educationElearning12x,
          },
          {
            singleblkImg: images.educationElearning2,
            singleblkImg2x: images.educationElearning22x,
          },
          {
            singleblkImg: images.educationElearning3,
            singleblkImg2x: images.educationElearning32x,
          },
        ],
      },
      {
        tabName: 'Retail breaking down',
        contentHeading: 'Social Marketplace App',
        contentPara:
          'Develop an app where sellers and purchasers can interact without any middleman to buy and rent food trucks and land spaces. Usage of microservices plays a role in breaking down multiple large operations into smaller services decoupling all tasks.',
        contentSingleProps: [
          {
            singleblkImg: images.mobecomm1,
            singleblkImg2x: images.mobecomm12x,
          },
          {
            singleblkImg: images.mobecomm2,
            singleblkImg2x: images.mobecomm22x,
          },
          {
            singleblkImg: images.mobecomm3,
            singleblkImg2x: images.mobecomm32x,
          },
        ],
      },
    ],
  },
  SimformGuaranteeData: {
    title: (
      <>
        Simform <span className="highlight-head">Guarantee</span>{' '}
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    ButtonText: 'Work with us',
    Link: '/contact/',
    GuaranteeList: [
      {
        icon: images.sgFlexibleIcon,
        guaranteeTitle: 'Flexible, efficient scaling',
        guaranteeInfo: 'Expand or reduce your remote team size on demand.',
      },
      {
        icon: images.sgTeamsIcon,
        guaranteeTitle: 'Team of 1,000+ engineers',
        guaranteeInfo:
          'Access vetted experts in the tech stack of your choice.',
      },
      {
        icon: images.sgLongTermIcon,
        guaranteeTitle: 'Long-term predictability',
        guaranteeInfo: 'Simform’s average engagement duration is 2 years.',
      },
    ],
  },
  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink: 'https://www.simform.com/blog/software-testing-cost/',
        featurecardTitle:
          'How Much Does Software Testing Cost? 9 Proven Ways to Optimize it.',
      },
      {
        blogLink:
          'https://www.simform.com/blog/microservice-testing-strategies/',
        featurecardTitle:
          'Microservices Testing Strategies, Types & Tools: A Complete Guide',
      },
      {
        blogLink: 'https://www.simform.com/blog/automated-functional-testing/',
        featurecardTitle:
          'Automated Functional Testing: Building a Successful Strategy',
      },
    ],
  },
}
